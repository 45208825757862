.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}
.h1, h1 {
    font-size: 2rem !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
}

.fa-whatsapp:before {
    content: "\f232";
    font-family: FontAwesome;
}


/* fund transfer */
/* Fund.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }
  
  .fund-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .fund-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 30px 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-image: url('/public/fund.jpg');
    width: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    position: relative;
    z-index: 1;
  }
  .fund-form::after{
    content: "";
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
  }
  
  .fund-form h2 {
    text-align: center;
    margin: 0;
  }
  
  .fund-form label {
    font-weight: bold;
    font-size: 14px;
  }
  
  .fund-form input {
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    background:rgba(255,255,255,0.3);
    color:#fff;
  }
  .fund-form input::placeholder{
    color:#fff;
  }
  
  .fund-form button {
    padding: 10px;
    background-color:#2fc8cd;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .fund-form button:hover {
    background-color: #0056b3;
  }
  