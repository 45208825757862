.headerContainer,
.leftContainer {
  background-color: #fff;
  max-width: 480px;
  width: 100%;
}



* {
  font-family: 'Dosis', sans-serif!important;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.leftContainer {
  min-height: 0%;
  position: relative;
}

.headerContainer {
  position: fixed;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  height: 0px;
  padding: 0;
  top: 0;
  z-index: 2;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navLogo > img {
  height: 36px;
  width: 36px;
}

.login-btn {
  border: 1px solid #003441;
  color: #003441 !important;
  border-radius: 3px;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 1px;
  /* height: 30px; */
  padding: 6px 16px;
  margin-right: 10px;
  background-color: #ffcc00;
  font-family: 'Poppins', sans-serif;
}
.login-btn:hover {
  background-color: #003441;
  color: #fff !important;
}

.rightContainer {
  /* background: url('https://annexlogics.com/static/media/08.17355bf8.webp'); */
  /* background: linear-gradient(to top, #d1c952ba, #d1c952ba),
    url('https://e1.pxfuel.com/desktop-wallpaper/965/285/desktop-wallpaper-file-ludo-ludo-game-board.jpg')
      no-repeat top center; */
  background-color: #fff;
  background-size: cover;
  overflow: hidden;
  right: 0;
  z-index: 4;
}

.rightContainer {
  bottom: 0;
  background-color: #000;
  color: #fff;
  left: 480px;
  position: fixed;
  top: 0;
  border-left: 10px solid #e0e0e0;
}

.rcBanner-footer > img {
  height: 25px;
  width: 25px;
  margin-top: 4px;
}

.rcBanner-img-container {
  bottom: 45%;
  position: absolute;
  width: 300px;
}

.rcBanner-img-container > img {
  height: auto;
  width: 300px;
}

.flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rcBanner-text {
  bottom: 35%;
  font-family: 'Roboto Condensed';
  font-size: 2em;
  font-weight: 400;
  position: absolute;
  text-transform: uppercase;
}

.rcBanner-text-bold {
  font-weight: 900;
}

.main-area {
  background-color: transparent;
  overflow: hidden;
  width: 100%;
}

.games-section {
  background: #fff;
  border-radius: 0;
  position: relative;
  width: 100%;
  margin-bottom: 36px;
}

.p-3 {
  padding: 1rem !important;
}

.games-section-headline {
  color: #959595;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 18px;
}

.games-window {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gameCard-container {
  /* margin-top: 20px; */
  justify-content: center;
  position: relative;
  width: 50%;
}
.gameCard{
  margin-right: 8px;
  margin-bottom: 8px;
}


.blink {
  animation: animate 2.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.gameCard {
  background-color: #fff;
  border-radius: 5px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

.gameCard-image {
  border-radius: 5px;
  height: 68.33%;
  width: 100%;
}
.gameCard-image img {
  border-radius: 5px;
  border: 5px solid #007bff;
  height: 210px;
  object-fit: cover;

}
.bank_input{
  background-color: #fff!important;
  color: #000!important;
  padding: 8px!important;
  font-size: 13px!important;
  font-weight: 600!important;
  border-radius:.375rem!important;
  border: 1px solid #dee2e6!important;
  height: 41.1px !important;
  padding-left: 16px!important;


}
input{
  font-weight: 700;
}
.btn-withdrow{
  background-color: #ffce04;
  border: none;
  padding: 6px 16px;
  border-radius: 30px;
  font-size: 14px !important;
  font-weight: 500;
  color: #000;
  margin-bottom: 30px !important;


}
.bank_input::placeholder{
  font-size: 15px;
}
.gameCard-icon,
.gameCard-title {
  display: flex;
  justify-content: center;
}

.gameCard-title {
  border-radius: 0 0 5px 5px;
  border: 1px solid #e0e0e0;
  border-top: none;
  color: #2c2c2c;
  font-weight: 700;
  padding: 15px 20px 10px;
}

.games-section-title {
  color: #3e7966;
  font-size: 1.2em;
  font-weight: 600;
  padding: 10px;
  /* background-color: #3e7966; */
  display: block;
  justify-content: center;
  align-items: center;
  /* border-radius: 4px; */
  border-bottom: 1px solid;
  text-align: center;
}

.rcBanner-footer {
  bottom: 40px;
  color: #fff;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  text-align: center;
}

.footer {
  /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */
  border-radius: 0 !important;
  position: relative !important;
  width: 100% !important;

  padding: 1% !important;
}

.footer-divider {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-links > a {
  color: #959595 !important;
  display: flex !important;
  font-size: 1.1em !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}

.footer-text-bold {
  color: #959595;
  font-size: 1em;
  font-weight: 700;
}

.footer-text {
  color: #959595;
  font-size: 0.9em;
  font-weight: 400;
}

a:hover {
  color: #170fa2 !important;
  text-decoration: underline;
}

a {
  color: #170fa2 !important;
  text-decoration: none;
  background-color: transparent;
}

.gameCard-container .goverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.collapseCard-container {
  padding: 30px 20px 20px;
  background-color: #fafafa;
}
.collapseCard {
  position: relative;
  width: 100%;
}
.collapseCard-body {
  padding-left: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.collapseCard-header {
  background-color: #fafafa;
  padding-left: 3px;
  padding-right: 3px;
  top: -13px;
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
}
.collapseCard-title {
  color: #676767;
  font-size: 0.7em;
  font-weight: 700;
  text-transform: uppercase;
}

.profile_info_small_box_main_row {

  padding: 24px 15px;
  background-color: #fff;
}
.profile_info_small_box_main {
  background-color: #fff;
}


.profile_info_small_box_main h3 {
  font-size: 15px;
  font-weight: 600!important;
  color: #000;
  
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.profile_info_small_box_main h4 {
  font-size: 18px;
  font-weight: 600!important;
  color: #000;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0px;
}
.profile_info_small_box_inner {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
}
/* .bg_1 {
  background-color: rgb(244, 195, 195);
}
.bg_2 {
  background-color: rgb(221 195 244);
}
.bg_3 {
  background-color: rgb(186, 250, 173);
} */
.profile_info_small_box_inner img {
  max-width: 30px;
}

.profile_div_main_box {
  /* background-color: #f6f8fa; */
  background-color: #ffcd01;
  display: flex
;
  align-items: center;
  justify-content: center;
  margin-top: 57px;
  padding: 5px;

 
}
/* .profile_div_main_box::after{
  background-color: #000;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: -1;
} */
.profile_wallet {
  background-color: #29c6cc  !important;
  color: #171717!important;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 16px;
  font-family: 'Poppins', sans-serif;
  box-shadow: none !important;
  border: none !important;
  outline: none;
  border-radius: 5px !important;
}
.profile_wallet:hover {
  color: #fff !important;
}

.complete_kyc_btn {
 
  color: #000 !important;
  font-size: 18px;
  padding: 6px 16px;
  font-family: 'Poppins', sans-serif;
  width: fit-content;
  border: 1px solid transparent;
  border-radius: 5px;
  /* margin-top: 10px; */
  height: 30px;
}
.complete_kyc_btn:hover {
  color: #fff !important;
}
.Profile_mytext__v9ly5 {
  color: #fff !important;
}
.complete_kyc_btn img {
  max-width: 30px;
  margin-right: 10px;
}
.color-theme{
  font-size: 16px !important;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    
  color: #7b1680;
}
.profile_div_main_inner {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.profile_div_main_inner .profie_img {
  border-radius: 50%;
  max-width: 80px;
  height: 60px;
  width: 60px;
  margin-right: 8px;
}
.profile_details h2 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700!important;
  font-size: 15px;
  line-height: 30px;
  color: #000;
  margin-bottom: 2px!important;
}
.profile_details h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  margin-top: 8px;
  margin-bottom: 0!important;
}

.Complete_Profile_box {
  padding: 7px 15px;
  
}
.border_top {
  border: 1px solid #e0e0e0;
}
.w3-bar-block .w3-bar-item.sidebar_link:hover a {
  color: #fff !important;
}
/* General styles for the toggle */
.label {
  background-color: #0d0d0d;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 9px;
  position: relative;
  height: 35px;
  width: 114px;
  z-index: 1;
  margin-left: 5px;
  margin-bottom: 0;
}
/* Toggle Switch Container */
.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Toggle Label */
.toggle-label {
  background-color: #0d0d0d;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  position: relative;
  height: 35px;
  width: 120px;
  transition: background-color 0.3s ease-in-out;
}

/* Toggle Ball */
.toggle-ball {
  background-color: #29c6cc;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  height: 30px;
  width: 50px;
  transition: transform 0.3s ease-in-out;
}

/* Text inside toggle */
.toggle-text {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  z-index: 1;
  user-select: none;
  transition: color 0.3s ease-in-out;
}

/* Checkbox Checked State */
#languageToggle:checked + .toggle-label .toggle-ball {
  transform: translateX(65px); /* Ball moves to the right for English */
}

#languageToggle:checked + .toggle-label .english {
  color: #fff; /* Highlight English when selected */
}

#languageToggle:not(:checked) + .toggle-label .hindi {
  color: #fff; /* Highlight Hindi when selected */
}

/* Ball styling */
.label .ball {
  background-color: #29c6cc;
  border-radius: 20px;
  position: absolute;
  top: 4px;
  left: 2px;
  height: 26px;
  min-width: 63px;
  transform: translateX(49px); /* Default position for Hindi */
  transition: transform 0.2s linear;
  z-index: -1;
}

/* Text styling */
.label span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

/* Checkbox checked (Hindi selected) */
#chk:checked + .label .ball {
  transform: translateX(0px); /* Ball moves to the left */
}

#chk:checked + .label .hindi {
  color: #29c6cc; /* Highlight Hindi */
}

#chk:not(:checked) + .label .english {
  color: #29c6cc; /* Highlight English */
}


.profile_headings {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700!important;
  font-size: 18px;
  line-height: 17px;
  color: #000000;

  margin-bottom: 10px;
  text-transform: capitalize;
  background-color: transparent!important;
}
.commaon_input_box {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  color: #000;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 8px 14px;
  border-radius: 5px;
  border: 2px solid #fff;
  background-color: rgba(255,255,255,0.3);
}
.commaon_input_box::placeholder{
  color: #000;
}
.commaon_label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #003441;
}
.profile_name_edit {
  margin-left: 30px;
  cursor: pointer;
}
.profile_edit_input {
  padding: 5px;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d3d;

  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.user_reder_code_box {
  display: flex;
  position: relative;
}
.user_reder_code_box .check_btn {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 7px;
}
.logout_div {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.logout_div a {
  background-color: #171717;
  margin: 0 15px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #fff !important;
  padding: 12px 24px;
  width: fit-content;
}
.logout_div button {
  background-color: #fe3347;
  margin: 0 15px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #fff !important;
  padding: 12px 24px;
  width: fit-content;
  border: none;
}
.logout_div a:hover {
  color: #fff !important;
}
.profile_history_btns_div {
  border: 1px solid #e0e0e0;
}
.profile_history_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_history_btns a {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #060606 !important;
  background-color: #f6f8fa;
  padding: 0.6rem 2rem;
  border: 1px solid #c8c8c8;
  border-radius: 20px;

  text-align: center;
}
.leftContainer {
  /* background-color: #fff; */
}
.Orher_page_main_section {
  margin-top: 20px;
  padding: 8px;
}
.Orher_page_main_section .profile_headings {
  text-align: left;
  margin-bottom: 20px;
  font-size: 20px!important;
}
.kyc-box{
  background-image:  url('/public/kyc.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 18px 14px;
  background-position: center;
  position: relative;
  z-index: 1;
  border-radius: 8px;
overflow: hidden;
}
.kyc-box:after{
  content: "";
  position: absolute;
  background-color: #000;
  top: 0;
  left: 0;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  z-index: -1;

}
.kyc-box .commaon_input_box::placeholder{
  color: #fff;
}
.Notifications_btns_div {
  display: flex;
  justify-content: space-between;
}
.Orher_page_main_section .nav {
  flex-wrap: nowrap;
  overflow: auto;
  scrollbar-width: none;
}
.Orher_page_main_section .nav-tabs .nav-link {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px !important;
  line-height: 5px !important;
  color: #26bbc1 !important;
  background-color: rgb(211 227 253);
  padding: 0.6rem 1rem !important;
  border: 5px solid #26bbc1 !important;
  border-radius: 4px !important;
  text-transform: capitalize;

    
}
.Orher_page_main_section .nav-tabs {
  display: flex;
  justify-content: space-between;
  border: none;
}
.Orher_page_main_section .nav-tabs .nav-link.active {
  background-color: #0169ff;
  color: #fff !important;
}
.card_date{
  text-align: left;
  display: flex;

}
.gamehis_games_section_headline__BVYUG{
  color: #fff!important;
  font-size: 0.75em;
  font-weight: 600!important;
  line-height: 18px;
  background-color: #23233d;
  padding: 2px 5px;
  border-radius: 3px;
}
.border-right{
  border-right: 2px solid #000!important;
}
.border-right-1{
  border-right: 2px solid #000!important;
}
.closingbl{
  background: black;
  color: white !important;
  padding: 2px 4px;
  border-radius: 2px;
}
.default-position{
  position: relative;
  left: 84px;
}
.btn-bg{
  width: 100px;
  font-size: 14px;
 
  padding: 5px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  text-transform: capitalize;
  margin-right: 5px;
  display: block;
  font-weight: 500;

}
.card_date{
  font-weight: 600;
}
.other_pages_card_main {
  display: flex
  ;
      align-items: center;
      justify-content: space-between;
      background: #d3e3fd;
      padding: 15px 8px;
      border: 2px solid #26bbc1 !important;
      border-radius: 8px !important;
      margin-top: 10px;
      /* background-color: #000; */
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
}
/* .other_pages_card_main:first-child{
  margin-top: 0;
} */
.refer_media_1{
  max-width: 30px;
  background: #fff;
  padding: 5px;
  border-radius: 2px;

}

.other_pages_card_main h3 {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000 !important;
}

.other_pages_card_main p {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  /* letter-spacing: 0.5px; */
  color: #000;
  margin-bottom: 0 !important;

}
.dander_text {
  color: rgb(13 173 26) !important;
}
.font-w700{
  font-weight: 700!important;
}
.dark_light_color {
  color: #5a5a5a;
}
.card_rupee {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141;
  background: rgb(255, 255, 255);
  padding: 6px 12px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px;
}
.card_date p {
  font-size: 14px !important;
}
.transaction_msg {
  font-size: 12px !important;
}
.transaction_right_side_main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: end;
  margin-left: auto;
}
.default-date{
  width: 100px;
}
.mb_space {
  padding-bottom: 80vh;
  padding-top: 30px;
  overflow-x: clip;
  height: inherit;
}
.mb_space_1{
  padding-bottom: 60px;
  padding-top: 30px;
}

/* =========Add cash page=============== */

.add_amount_main_box_inner .label {
  white-space: nowrap;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #000000;
}
.enter_amount_input_box {
  position: relative;
}
.enter_amount_input_box1 {
  position: relative;
}
.enter_amount_input_box span {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141 !important;
}
.enter_amount_input_box1 span {
  position: absolute;
  top: 30%;
  left: 10px;
  transform: translate(0, -50%);
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141 !important;
}
.add_amount_main_box_inner input {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141 !important;
  width: 100%;
  padding: 8px 0 8px 35px;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
}
.add_amount_main_box_inner input:focus {
  border: 1px solid #242424;
}
.add_amount_min_max {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.add_amount_min_max p {
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #f86262 !important;
}
.img-size{
  width: 22px;
  height: 27px;
  text-align: center;
  
}

.add_amount_buttons_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.add_amount_buttons_main button {
  border: none;
  background-color: #000;
  padding: 10px 15px;
  border-radius: 4px;
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  letter-spacing: 0.5px;
  display: inline-flex;
  align-items: center;
}
.add_amount_buttons_main button svg{
  margin-right: 2px;
}
.add_amount_buttons_main button:focus {
  background-color: #0169ff;
}
.bg-upi{
  background-color: #414141;
  color: #fff;
}

.add_cash_nest_btn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_cash_nest_btn button {
  background-color: #28c6cb;
  width: 80%;
  margin: auto !important;
  border: none;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  letter-spacing: 0.5px;
}
.reed .commaon_input_box{
  border: 1px solid #000;
}

.add_cash_nest_btn button .icons {
  color: #fff !important;
  font-size: 20px;
  margin-left: 6px;
}
.deposit_service {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  letter-spacing: 0.5px;
}

.add_cash_small_haedings {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}

.samll_btn {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
  border: none;
  box-shadow: none;
  background-color: #000;
  color: #fff !important;
  border-radius: 5px;
  padding: 6px 16px;
  cursor: pointer;
}
.samll_btn_game{
  background-color: #2c41cf;
}
.samll_btn .icons {
  margin-right: 5px;
  font-size: 22px;
}
.check_btn{
  background-color: #000;

}
.normal_btn_text {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}
.samll_card_btn_type {
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
}
.support_samll_card_btn_type {
  background-color: #f6f8fa;
  padding: 0.2rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.support_samll_card_btn_type a,
.support_samll_card_btn_type span {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  letter-spacing: 0.5px;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.select_box {
  margin-top: 30px !important;
  width: 100%;
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
}

.select_box option {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}

.company_address {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  letter-spacing: 0.5px;
  color: #000 !important;
  margin-top: 30px !important;
}

.support_img_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.support_img_box img {
  max-width: 50%;
}

/* ===============refer page=============== */
.refer_page_main_section {
  padding: 11px;
  padding-top: 36px;
}
.refer_page_top_main_box_outer {
  border-radius: 8px;
  border: 2px solid rgb(126, 126, 126);
}
.refer_page_top_main_box h2 {
  /* background-color: #ffcc00; */
  padding: 10px;
  text-align: center;
  /* font-size: 18px !important;
  font-weight: 600 !important;
  font-family: var(--FF) !important;
  color: #222222 !important; */
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
color: #fff;
  background-color: #000;
  padding: 0.6rem;
  font-size: 14px;
  /* border: 1px solid #c8c8c8; */
}

.refer_page_top_main_box_inner {
  display: flex;
  padding: 10px;
}
.refer_page_top_main_box_inner_single {
  width: 50%;
  text-align: left;
}
.refer_page_top_main_box_inner_single h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
 
  margin-bottom: 10px;
  text-transform: capitalize;
}
.bo_le {
  border-right: 1px solid rgba(246, 7, 7, 0.5);
}
.refer_page_top_main_box_inner svg{
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex
;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  border-radius: 8px;
  margin-right: 8px;
  padding: 9px;
}
.refer_page_top_main_box_inner svg path{
  fill: #fff;
}
.Refer_code {
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    /* margin: 14px; */
    background: #46ced340;
    /* padding-top: 14px; */
    padding-bottom: 10px;
    border-radius: 10px;
    border: 2px dotted #8b6800;
}
.Refer_code h3 {
  background-color: transparent!important;
  border: 0!important;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
}
.Refer_code p{
  background-color: transparent !important;
  border: 0 !important;
  font-size: 16px !important;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;


}
.Refer_code span{
  font-size: 1rem!important;
}
.Refer_code button {
  width: 20%;
  height: 35px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color:#29c6cc;
  border-radius: 5px;
  margin-top: -7px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14.9px!important;
  line-height: 17px;
  color: #fff !important;
}

.or_div h2 {
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 17px;
  letter-spacing: 1px;
}

.whatsapp_share_btn {
  text-align: center;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  margin: 20px 0;
}
.whatsapp_share_btn a {
  background-color: #58e043;
  color: #fff !important;
  padding: 8px 33px;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  line-height: 26px;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  max-width: 48px;
  margin-right: 8px;
}
.whatsapp_share_btn a:hover {
  background-color: #000000;
  color: #fff !important;
}

.refer_rules p {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

.main_heading_with_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main_heading_with_btn .profile_headings {
  margin-bottom: 0;
}
.main_heading_with_btn .profile_wallet {
  border-radius: 5px;
}
.main_heading_with_btn .profile_wallet .icons {
  font-size: 20px;
  margin-right: 6px;
}

.wallet_page_cards{
  background-image: linear-gradient(to left, #29c6cc, #000000);
  padding: 1.5rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  margin-bottom: 20px;
  

}
.wallet_page_cards.style-1 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.wallet_page_cards.style-1 :after{
     width: 159px;
     height: 159px;
     display: block;
     border-radius: 50%;
     background: linear-gradient(131.92deg, #ffffff 14.55%, rgba(49, 24, 152, 0.5) 95.62%);
     position: absolute;
     content: "";
     right: 0%;
     top: 25%;
     animation: MoveX 5s ease infinite;
     z-index: -1;
     opacity: 0.1;
 }

        

@keyframes  MoveX{
  100%, 0%{
         transform: translate(0,0);
     }
     50%{
         transform: translate(30px,-70px);
     }
}
.refral{
     display: flex  ;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
  
}
.icon_box{
  width:40px!important;
  height: 40px!important;
  border-radius: 8px;
  filter:invert(1);
}
.kyc_page_cards {
  background-color: #7b1680;
  padding: 1.5rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  margin-bottom: 20px;
}
.kyc_page_cards p {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
  margin-top: 10px;
}
.block_end_text {
  display: flex;
  align-items: center;
  justify-content: end;
}
.wallet_page_cards p {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
}

.small_para {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  /* letter-spacing: 0.5px; */
  color: #666666;
  text-transform: capitalize;
}
table tr td {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  /* letter-spacing: 0.5px; */
  color: #666666;
  text-transform: capitalize;
}
.small_para a {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  color: #666666;
}
.other_page_small_headings {
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #222222;
}

/* battle page */

.Challenges_main_section {
  background-color: #f5f5f5;
  margin-bottom: 20px;
}

.Challenges_main_section h2 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  font-family: 'Poppins', sans-serif !important;
  text-align: left;
  margin-bottom: 30px;
  margin-top: 80px;
}

.Challenges_single_box_main {
  text-align: left;
  border: 1px solid #454545;
  border-top: 0;
  border-radius: 5px;
  margin-bottom: 20px;
}
.Challenges_single_box_main .top__heading {
  padding: 5px 10px;
  background-color: #fe3347 !important;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.Challenges_single_box_main h3 span {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  color: #fff;
  line-height: 25px;
}
.Challenges_single_box_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-top: 1px solid #3e3e3e; */
  padding: 15px;
}

.Challenges_single_box_img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Challenges_single_box_img img {
  max-width: 35px !important;
  margin-right: 15px;
}

.Challenges_single_box_img h4 {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  color: #0e0e0e;
  margin-bottom: 0;
}
.running_bet_card {
  margin-top: 10px;
}
.running_bet_card .Challenges_single_box_main {
  background-color: whitesmoke !important;
  background-color: #f6f8fa;
  padding: 0.6rem;
  border: 1px solid #c8c8c8;
  border-radius: 0.375rem;
  position: relative;
}
.bet_vs_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 35px !important;
}
.running_bet_card .Challenges_single_box_main .top__heading {
  background-color: transparent !important;
}
.running_bet_card .Challenges_single_box_main h3 span {
  color: #003441;
}
.running_bet_card .Challenges_single_box_img h4 {
  font-size: 20px !important;
}
.running_bet_card .Challenges_single_box_inner {
  padding: 5px 15px;
  /* border-top: 1px solid rgba(154, 154, 154, 0.586); */
}

.bg_green {
  /* background-color: rgb(72, 255, 72); */
  width: fit-content;
  float: right;
  padding: 2px 3px 0 3px;
  border-radius: 20px;
  margin-bottom: 10px;
  font-size: 10px;
}

.profile_info_small_box_main_row .rows {
  margin: 0px !important;
}

.battle_card_main {
  background-image: url('/public/images/login-bg.avif');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  overflow: hidden;
  padding: 0.6rem 0rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 8px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.battle_card_main:after{
  content: "";
  position: absolute;
  background-color: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: -1;
}
.card_bottom_border {
  
}

.battle_card_main .headings {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #fff!important;
  padding-left: 0.6rem !important;
}
.battle_card_inner_bottom img{
  filter: invert(1);
}
.card_icons {
  font-size: 26px;
  color: #fff;
}
.delete_buttons {
  background-color: #ffcc00;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px !important;
  margin-top: -10px!important;
}
.battle_card_inner_bottom {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.6rem !important;
  align-items: center;
}

.header_top_message {
  position: absolute;
  top: 0;
  z-index: 9999;
  background-color: #000;
  width: 480px;
  text-align: center;
  padding: 8px;

  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #fff !important;

  max-width: 480px;
  width: 100%;
  position: fixed;
}

.game_live_status_position {
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 8px;
  font-weight: 600;
  color: #000;
  background: #c2c2c2;
  padding: 1px 5px;
  border-radius: 5px;
  line-height: 1.7;
}

.battle_card_main img{
  
}
.amount span{
  color: #fff;
}
.text-red{
  color: red!important;
}
.battle_card_main.style-1:after{
  content: none;
}
.filter_coustom{
  filter: invert(1);
}
.whatsapp_share_btn .icons {
  font-size: 20px;
}
.whatsapp_share_btn a:nth-child(2) {
  background-color: rgb(229, 69, 69);
}
.whatsapp_share_btn a:nth-child(3) {
  background-color: #6e6eda;
}

.transaction_details_modal_body h1 {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #000000 !important;
  text-align: center;
  margin-bottom: 40px;
}

.transaction_details_modal_body .d_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.transaction_details_modal_body .d_flex h2 {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #000000 !important;
}

.transaction_Success {
  border-radius: 20px;
  padding: 5px;
}
.T_success {
  background-color: rgb(47, 248, 47);
}
.T_pending {
  background-color: rgb(248, 47, 47);
}
.T_process {
  background-color: rgb(31, 31, 251);
  color: #fff !important;
}

.modal-dialog {
  max-width: 478px !important;
}
.transaction_main_section_modal .modal-dialog {
  margin-left: 0px !important;
}

.eye_button {
  border: none;
  box-shadow: none;
  background-color: green;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eye_button .icons {
  color: #fff;
  font-size: 22px;
}
.transaction_footer {
  border-top: 1px solid #3f3b3b;
  padding-top: 10px;
}
.transaction_footer p {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.7rem !important;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #f93131 !important;
  margin-bottom: 0 !important;
}
.battle_plat_btn {
  position: relative !important;
}

.request_btn {
  background-color: #ffcc00  !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
}
.reject_btn {
  background-color: #f93131 !important;
  color: #fff;
  text-transform: capitalize;

  border: none;
  box-shadow: none;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
}
.bet_player_name {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
  color: white !important;
}

.Home_betCard_playerName__L\+i1O {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
}

.download_btn_bottom {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
}
.commaon_para {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
}
.deposite-text{
  font-size: 16px;
  font-weight: 700!important;
}
.game_result_submit_model .cancel_popup_reason_popup {
  padding-right: 0 !important;
}
*{
  font-family: 'Poppins', sans-serif!important;
}

.swal2-popup {
  background-color: rgb(44, 65, 207) !important;
}
.swal2-title,
.swal2-html-container {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  text-transform: capitalize !important;
  color: #fff !important;
}

.cancel_popup_reason_popup {
  padding-right: 0 !important;
}
.cancel_popup_reason_popup .modal-header {
  height: 0;
}
.win_modal .modal-content {
  margin-top: 0px;
}
.screenshot_img {
  max-width: 100%;
  max-height: 274px;
  object-fit: contain;
}

.profile_page .Orher_page_main_section .nav-tabs {
  justify-content: space-around !important;
  background-color: red !important;
}
.modal-content {
  padding: 15px;
}
.play_btn_color {
  color: #fff !important;
  background-color: #2c41cf !important;
}
.start_btn_color {
  color: #fff !important;
  background-color: #2c41cf !important;
}

.battle_divider {
  background: #fe3347;
  width: 100%;
  height: 3px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.games_Section_Title {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #28a745;
}
.game_section{
  color: #000;
}

.bank_page_cards {
  background-color: #fff;
  border: 1px solid #17d773;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bank_page_cards h3 {
  color: #17d773;
  font-size: 20px;
  font-weight: 500;
}
.bank_page_cards ul li {
  color: #2b2b2b;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.modeButton{
  display: flex;
  align-items: center;
  justify-content: flex-start;
 
}
.small_btn{
 font-weight: 700;
}
/* .small_btn.active {
  background-color: #156fd6; 
  color: #fff !important; 
  padding: 10px !important;
  border-radius: 5px;
} */

.game_tabs_btn{
  background-color: #ffcc00 !important; 
  color: #fff !important; 
  padding: 12px 12px  !important;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;

}
.game_tabs_btn:hover{
  color: #fff !important; 

}
.game_tabs_btn.active {
  background-color: #fc424a !important; 
  color: #fff !important; 
}
@media screen and (max-width: 480px) {
  .running_bet_card .Challenges_single_box_main {
    padding: 0;
  }
  .btn-bg{
    width: 94px;
  }
  .gameCard-image img{
    height: 182px;
  }
  .profile_info_small_box_main {
    padding: 5px;
    height:75px;
  }
  /* .other_pages_card_main{
    padding: 0.5rem 0.2rem !important;
  } */
  .Complete_Profile_box {
    padding: 10px 0px;
    margin: 5px;
  }
  .Complete_Profile_box .complete_kyc_btn {
    width: 97%;
  }
  .profile_info_small_box_main h3 {
    font-size: 14px;
  }
  .bank_page_cards ul li {
    font-size: 10px;
  }
  .profile_info_small_box_main h4 {
    font-size: 14px !important;
    margin-bottom: 0!important;
  }
  .profile_info_small_box_main_row .col-4 {
    margin: 0 !important;
    padding: 0 5px;
  }
  .profile_info_small_box_inner {
    width: 16px;
    height: 16px;
    margin-bottom:4px ;
  }
  .profile_info_small_box_inner img {
    max-width: 16px;
  }
  .profile_headings,
  .profile_details h2,
  .profile_wallet,
  .Profile_mytext__v9ly5,
  .commaon_input_box {
    font-size: 14px !important;
  }
  .profile_wallet,
  .complete_kyc_btn {
    padding: 4px 8px;
  }
  .profile_details h3 {
    font-size: 12px;
    margin-top: 0;
  }
  .profile_details h2 {
    margin-bottom: 0;
  }
  .profile_div_main_inner .profie_img {
    max-width: 40px;
    height: 40px;
    width: 40px;
  }
  .profile_div_main_box {
    padding: 5px;
  }
  .Orher_page_main_section {
    padding: 7px;
  }
  .Orher_page_main_section .nav-tabs .nav-link {
    /* padding: 8px !important; */
    margin-right: 10px !important;
  }
  .other_pages_card_main h3 {
    font-size: 14px;
  }
  .other_pages_card_main p {
    font-size: 12px !important;
  }
  .profile_info_small_box_main_row {
    padding: 5px 5px;
  }
  .profile_div_main_box {
    margin-top: 57px;
    border-left: 0;
    border-right: 0;
  }
  .profile_history_btns a {
    font-size: 14px;
    margin-right: 5px;
    padding: 0.6rem 1rem;
  }
  .support_samll_card_btn_type {
    padding: 5px !important;
  }
  .support_samll_card_btn_type a,
  .support_samll_card_btn_type span {
    font-size: 12px !important;
  }
  .running_bet_card .Challenges_single_box_main h3 span {
    font-size: 14px;
  }
  .running_bet_card .Challenges_single_box_img h4 {
    font-size: 14px !important;
  }
  .transaction_details_modal_body .d_flex h2 {
    font-size: 12px;
  }
  .transaction_details_modal_body h1 {
    font-size: 20px;
  }
  .header_top_message {
    font-size: 10px;
  }
  .rcw-conversation-container {
    min-width: auto !important;
    max-width: inherit !important;
  }
  .game_tabs_btn{
    padding: 10px 2px  !important;
    font-size: 10px;
  }
}
.tems_box{
  border: 1px solid #c2c2c2;
  padding: 10px;
  border-radius: 10px;
  margin-left: 14px;
  margin-right: 14px;
}
.accordion-item{
  border:1px solid #c2c2c2!important;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.tems_box button{
  background-color: transparent!important;
  border:0!important;
  font-size: 16px!important;
  padding: 0!important;
}
.tems_box.accordion-header{
  line-height: 0!important;
  margin-bottom: 0;
}



.accordion-collapse.collapse.show{
  margin-top: 20px !important;
  border-top: 1px solid #c2c2c2;
  padding-top: 3px;
}
.back_sidebar{
  width: 30px;
    display: inline-block;
    height: 30px;
    background: #28c6cb;
    border-radius: 4px;
    text-align: center;
    line-height: 26.6px;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 5px;
    cursor: pointer;
}
.main_balance{
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 10px;
}
.Add_balance{
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  border-bottom: 1px solid #888;
  
}
.Add_balance strong{
  color: #29c6cc;
}
.wallet_btn {
  overflow: hidden;
}
.wallet_btn a{


  color: #fff !important;

  display: flex;
  line-height: 1;
  align-items: center;
  overflow: hidden;

  background-color: var(--blue);
  background-color: #19a40d;
  font-size: 12px;
  padding: 12px 14px;
  font-weight: 600;

  border-radius: 20px;
  text-decoration: none;
  color: var(--white);
  border: none;
  box-shadow: none;



}
.fs-12{
  font-size: 12px!important;
  font-weight: 500;
}
h3.custom-balance {
  margin-bottom: 20px !important;
  font-size: 20px;
}
.wallet_btn.style-1 a{
  /* padding:6px 21px; */
  background-color: red;
}
.fs-20{
  font-size: 20px;
}
.toggle-container.style-1 .toggle-button {
  padding: 1px 15px;
}
.wallet_btn a:hover{
  color: #fff!important;
  font-weight: 500!important;
}
.toggle-container.style-1 {
  width: 139px;
  margin-left: 0;
}
.Complete_Profile_box{
  border-bottom: 1px solid #c2c2c2;
}
.icon_box-1{
  background-color: rgb(41 198 204 / 15%);
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  margin-right: 9px;
  /* padding: 4px; */

}
.icon_box-1 img{
  max-width: 25px;
  margin-right: 0;
}
.profile_info_small_box_main{
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #c2c2c2;
  padding: 10px;

}
.main-header{
  display: flex;
  justify-content: center;
  font-size: 20px!important ;
}
.h5-bold{
  font-weight: 600 !important;
}


.contax-box{
  background-color: var(--support_card_bg);
    border: 1px solid #7e7e7e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: #64646f33 0 7px 29px;
    margin-bottom: 30px;
}
.contax-box img{
  width: 40px!important;
  margin-right: 5px;
}
.contax-box span{
  font-size: 16px;
  
  
}
.profie_img{
  width: 60px;
  height: 60px;
}

.battleCard .result-area {
  margin-bottom: 40px;
}

.tab-content > .active {
  display: block;
  margin-bottom: 30px;
}
.eye{
  font-size: 25px;
  color: #d017ff;
}

.txn_masg{
  display: flex;
  align-items: center;
  justify-content: space-around;
}



/* eye Withdraw css  */

.header-container {
  display: flex;
  align-items: center;
  position: relative;
}

.title-msg {
  margin-bottom: 0;
  margin-left: 12px;
}

.eye-icon {
  margin-left: auto;
  margin-right: 12px;
  cursor: pointer;
}

.popup-box {
  position: absolute;
  top: 40px;
  right: 10px;
  background: #facc06;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  width: 200px;
  font-size: 14px;
  text-align: center;
}


.side-class{
  text-align: left;
  margin-left: 16px;

}
.justify-contemt-start{
  justify-content: start;
}