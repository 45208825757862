/* #hambergar:hover {
  background-color: white !important;
} */
.w3-button {
  /* background-color:blueviolet; */
  color: white;
  /* position:fixed */
  margin: auto;
  right: 0;
  top: 20px;
  /* right:/20px; */
  padding: 0.25rem;
  transition: transform 0.3s ease-in-out;
  /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}
.w3-button.active {
  transform: translateX(-100px);
}
.menu_button{
  background: transparent;
  border: none;
}
.icon_style_white{
  filter: invert(1);
}
.app-download{
  padding: 3px 8px;
  border-bottom: 0;
  border: 1px solid #29c6cc;
  border-radius: 4px;
  margin-right: 10px;
}
.app-download .icon{
  padding: 0;
}
.app-download span{
  color:  #29c6cc;
  font-size: 13px;
  margin-left: 6px;
  font-weight: 700;
}

.app-download img{
  filter: brightness(22.2);
}
.app-download:hover{
  background-color: transparent!important;
}
.header_profile{
  background:url('/public/header-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 0px 10px 0px;
}
.header_profile div div {
  display: flex;
  justify-content: center;
}
.header_user{
  height: 60px;
  width: 60px;
  border-radius: 50px;
  margin-bottom: 10px;
  border: 1px dashed #fff;
}
.header_profile h5 {
  color: #fff;
  font-family: 'Mulish', sans-serif !important;
font-size: 24px;
}
.header_profile h3{
  color: #fff;
font-family: 'Mulish', sans-serif !important;
font-size: 14px;
}
.w3-sidebar {
  width: 100%;
  max-width: 480px;
  height: 100%;
  background-color: #23233d;
  color: #fff !important;
  position: fixed;
  top: 0;
  left: -500px;
  height: 100vh;
  transition: all 0.5s;
  z-index: 99999999 !important;
  scrollbar-width: thin;
}
.w3-container {
  padding: 0px;
  top: 0 !important;
  margin: auto;
  background: #fff !important;
}
.w3-teal {
  background: white !important;
}
.w3-bar-block .w3-bar-item {
  background-color: #23233d;

  /* padding: 20px; */
  /* height: 60px; */
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  color: #f5f1f1 !important;
}

.w3-bar-block .w3-bar-item:hover {
  background: #000 !important;
  color: #fff !important;
}

.icon {
  padding: 10px;
  border-radius: 8px;
}
.w3-bar-block .w3-bar-item:hover .icon {
  background-color: #e7e7e7;
  padding: 10px;
  border-radius: 8px;
}
.arrow {
  position: absolute;
  right: 25px;
}
.arrow > img {
  height: 11px;
  width: 6px;
  max-width: 100%;
}

.icon {
  overflow: hidden;
  position: relative;
}
.icon > img {
  /* height: 28px; */
  width: 28px;
  /* max-width: 100%; */
}
#sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: none;
  transition: all 0.5s ease-in-out 0s;
}



.dlmbtn {
  width: 30px;
  height: 30px;
  padding: 5px 5px;
  background-color: white;
  border-radius: 50%;
  border: none;
  margin-top: 12px !important;
  margin-left: 7px !important;
  box-shadow: 0 0 1px black !important;
}
.dlmbtn img {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* ===============new css=============== */
.sidebar_link {
  border-bottom: 1px solid #dbdbdb !important;
}
.sidebar_link h3 {
  font-size: 16px;
  font-family: 'Poppins', sans-serif!important ;
  margin-left: 8px;
  margin-bottom: 0 !important;
  font-weight: 500 !important;

}
.sidebar_link a {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  margin-left: 12px;
  margin-bottom: 0;
  color:#fff!important;
}

.alrt_msg_div {
  /* background-color: #facc06; */
  /* color: white; */
  background-color: #ffcd01;
  border: 1px solid #fff;
  padding: 8px 5px;
  border-radius: 3px;
  margin-bottom: 2px;
  text-align: cente
}
.alrt_msg_div h5 {
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  /* color: #fff; */
  font-weight: 600 !important;

  font-family: 'Poppins', sans-serif !important;
  margin-bottom: 0 !important;
}
.alrt_msg_div span {
  font-size: 1.2rem;
  line-height: 16px;
  text-align: left;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}

.alrt_msg_div a {
  font-size: 1rem !important;
  line-height: 16px;
  text-align: left;
  color: #f5f5f5 !important;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}

.alrt_deposit {
  background-color: #f5f5f5!important;
  padding: 5px;
  margin-bottom: 10px;
  border: 2px solid red;
  border-radius: 0.375rem;
}

.alrt_deposit .Refer_code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 5px;
  background: #46ced340;
  /* padding-top: 14px; */
  padding-bottom: 10px;
  border-radius: 10px;
}
.alrt_deposit .Refer_code p{
 margin-bottom: 0px !important;
}

body {
  position: relative !important;
}
.bottom_menus_main {
  position: fixed !important;
  bottom: 0px !important;
  max-width: 480px;
  width: 100%;
  margin: auto !important;
  /* background-color: rgb(0, 0, 0); */

  z-index: 999999999999;
}
.main_bottom_menus {
  max-width: 100%;
  margin: auto !important;
  background-color: #000;
  border-radius: 0px;
  padding: 15px 7px;
}
.bottom_link_main {
  display: flex;
  justify-content: space-between;
}
.bottom_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.bottom_link span {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif!important;
  display: block;
  transition: 0s all ease-in-out;
}
.active_link {
  transition: 0s all ease-in-out;
}
.active_link span,
.active_link .sidebar_link_icon {
  color:#29c6cc !important;
  transition: 0s all ease-in-out;
}

.active_link {
  color: #888 !important;
  
  padding: 5px 8px;
  border-radius: 20px;
  transition: 0s all ease-in-out;
}


.active_link span {
  display: block;
  color: #29c6cc !important;
  transition: 0s all ease-in-out;
}
.active_link span .sidebar_link_icon {
  color: #29c6cc !important;
}
.sidebar_link_icon {
  color: #888 !important;
  font-size: 22px;
  margin-bottom: 2px;
}
.active_link .sidebar_link_icon {
  font-size: 20px;
  color: #29c6cc !important;
}

@media screen and (max-width: 480px) {
  .bottom_link span {
    font-size: 12px;
  }
  .main_bottom_menus {
    padding: 15px 8px;
  }
  .sidebar_link_icon {
    font-size: 18px;
  }
}

/* ============Game_Tournaments_box_main_section============== */
.Game_Tournaments_box_main_section {
  padding: 0 15px;
}
.mb_space {
  padding-bottom: 100px;
}

/* ============top menu bar============== */

.top_menu_bar_main .icons {
  font-size: 20px;
  color: #4e4e4e;
}

.top_menus_link {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_menu_bar_main .active_link .icons {
  color: #fff;
  transition: 0s all;
}

.top_menus_btns {
  width: auto;
  height: auto;
  /* margin-right: 10px; */
  /* background-color: rgb(0 52 65); */
  padding: 5px 8px;
  border-radius: 20px;
  transition: 0s all ease-in-out;
}

.top_menus_btns .icons {
  color: #4e4e4e;
  font-size: 18px;
  transition: 0s all ease-in-out;
}

.top_menus_link span {
  color: #000000 ;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin: 0 4px 0 4px;
  transition: 0s all ease-in;
}
.top_menus_link {
  color: #000000 ;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin: 0 4px 0 4px;
  transition: 0s all ease-in;
}

.top_menu_bar_main .active_link {
  /* background-color: red; */
  padding: 0;
}

.top_menu_bar_main .notification_btn.active_link {
  border: 1px solid #fff;
}

.notification_btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4e4e4e;
  border-radius: 50%;
  margin-left: 15px;
  margin-top: 15px;
  background-color: #fff;
}
/* .top_menus_link .notification_btn{
  background-color: #fff !important;
} */

.wallet_balance_btn {
  border: 1px solid #c2c2c2;
  width: auto;
  height: auto;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #facc06;
  border-radius: 6px;
}
.wallet_balance_btn .icons {
  color: #000;
  font-size: 16px;
}

.wallet_balance_btn span {
  color: #000;
  margin-left: 8px;
}
.wallet_balance_btn  {
  color: #000;
  margin-left: 5px;
}
.wallet_btn  {
  color: #000;
  margin-left: 5px;
}
.wallet_balance_btn_flex {
  display: flex;
  flex-direction: column;
}
.header_navlink_main h5 {
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 2px;
  text-align: center;
}
.custom_select_box select {
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  margin-left: 12px !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
}
.custom_select_box option,
.custom_select_box label {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  margin-left: 12px;
  margin-bottom: 0;
}
.dropdown .dropdown-menu .dropdown-item a {
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;

  margin-bottom: 0;
  color: #444 !important;
}
.custom_select_box:hover,
.custom_select_box:focus {
  border: none !important;
  background-color: transparent !important;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus {
  box-shadow: none !important;
}
.w3-bar-block .w3-bar-item:hover .custom_select_box select {
  background: #003441 !important;
  color: #fff !important;
}

.custom_dropdown {
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
}
.dropdown_link {
  margin-top: 30px;
}
.dropdown_link li {
  margin-left: 20px;
}
.dropdown_link a {
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  color: #003441 !important;
}

.custom_dropdown .btn {
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;

  margin-bottom: 0 !important;
  font-weight: 500 !important;
}

.w3-bar-block .w3-bar-item:hover .custom_dropdown.btn {
  background: #003441 !important;
  color: #fff !important;
}

.custom_dropdown {
  /* overflow: inherit; */

  position: unset !important;
  margin-top: 20px;
}


.custom_select_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.language-select {
  margin-bottom: 10px;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
 width: 160px;
 padding: 3px 5px;
 border-radius: 30px;
 border: 1px solid #28c6cb;
 margin-left: 10px ;
 background-color: rgb(38 187 193 / 10%);
}

.toggle-button {
  padding: 5px 15px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s;
  background: transparent ;
  color: #fff;
}

.toggle-button.active {
  background-color: #28c6cb;
  color: white;
}
.user_reder_code_box .commaon_input_box{
  border: 1px solid #000;
}

.slider {
  width: 100%;
  height: 15vh;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 5px #fff;
  margin-bottom: 10px;
}

.slides {
  display: flex;
  animation: slide 9s infinite; /* Change slide every 9 seconds */
}

.slide {
  width: 100%;
  height: 15vh;
  flex-shrink: 0;
  /* animation-delay: 8s; */
  background-color: #1abc9c; /* Default background color */
}
.slide1 img {
  width: 100%;
  height: 15vh;
}

.slide:nth-child(2) {
  background-color: #2ecc71;
  animation-delay: 3s; /* Delay the animation of the second slide */
}
.slide2 img{
  width: 100%;
  height: 15vh;
}

.slide:nth-child(3) {
  background-color: #3498db;
  animation-delay: 6s; /* Delay the animation of the third slide */
}
.slide3 img {
  width: 100%;
  height: 15vh;
}

/* @keyframes slide {
  0% { transform: translateX(0); }
  33.33% { transform: translateX(0); }
  33.33% { transform: translateX(-100%); }
  66.66% { transform: translateX(-100%); }
  66.66% { transform: translateX(-200%); }
  100% { transform: translateX(-200%); }
} */
@keyframes slide {
  0% { transform: translateX(0); }
  25% { transform: translateX(0); }
  33.33% { transform: translateX(-100%); } /* Slide 1 moves out */
  58.33% { transform: translateX(-100%); }
  66.66% { transform: translateX(-200%); } /* Slide 2 moves out */
  91.66% { transform: translateX(-200%); }
  100% { transform: translateX(-200%); } /* Slide 3 moves out */
}




/* Toggle Button hindi english */

.switch {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  font-size: 12px;
  
}

.switch > button {
  position: absolute;
  top: 18px;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #57155a;
}

input.check-toggle-round-flat:checked ~ .on {
  color: rgb(230, 77, 22);
}

.switch > button.on {
  left: 0;
  padding-left: 2px;
  color: #57155a;
  background: transparent;
  border: none;
}

.switch > button.off {
  right: 0;
  padding-right: 4px;
  color:  rgb(230, 77, 22);
  background: transparent;
  border: none;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 135px;
  height: 35px;
  margin-top: 10px;
  /* background-color: #F36F25; */
  box-shadow: 0 0 2px #fff;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: white;
  -webkit--moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 62px;
  background-color: #fff;
  box-shadow: 0 0 5px #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

/* input.check-toggle-round-flat:checked + label {
} */

input.check-toggle-round-flat:checked + label:after {
  margin-left: 64px;
}


/* Toggle Button Drak Light */

.switch {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  /* width: 130px; */
}

.switch > span {
  position: absolute;
  top: 20px;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #57155a;
}

input.check-toggle-round-flat:checked ~ .on {
  color: rgb(230, 77, 22);
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
  color: #57155a;
}

.switch > span.off {
  right: 0;
  padding-right: 4px;
  color:  rgb(230, 77, 22);
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 135px;
  height: 35px;
  margin-top: 10px;
  /* background-color: #F36F25; */
  box-shadow: 0 0 2px #fff;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: white;
  -webkit--moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 62px;
  background-color: #fff;
  box-shadow: 0 0 5px #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}


input.check-toggle-round-flat:checked + label:after {
  margin-left: 64px;
}


/* d&l */


.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #111;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  margin-top: 22px;
  margin-left: 7px;
  box-shadow: 0 0 2px #fff;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon {color: #f1c40f;}

.fa-sun {color: #f39c12;}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}
.w3-bar-block .w3-bar-item:hover .toggle-button{
  color:#fff;
}