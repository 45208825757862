.bg_maint {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-position: center;
  height: 300px;
  width: 100%;
}
.bg_cont {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* height: 100vh; */
  background-color: #ffcd01;
  border: 1px solid #fff;
  padding: 8px 5px;
  border-radius: 3px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 700;
}
